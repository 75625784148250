
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { GenerateYearOptions } from "@/assets/scripts/helper";
 
@Component
export default class CfgEmpreendimento extends Vue {
    filter: {
      empresaId:number;
      ano:string;
       dataPagamentoInicial:string;
       dataPagamentoFinal:string;
    } = {
      empresaId:0,
      ano:'',
      dataPagamentoInicial:'',
      dataPagamentoFinal:''
    };

    valid = true; 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empreendimentos = [];
    empresas = [];
    anos = GenerateYearOptions();
      
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório EFD Contribuições', disabled: true, href: '/financeiro/painel/despesas'}
  ]
    
    mounted() {
      new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empresas = res.data.items
        }, 
      );
  }   

  Visualizar() {    
    if(!this.filter.dataPagamentoInicial && !this.filter.dataPagamentoFinal){
      this.$swal("Aviso", "Obrigatório preencher Data Inicial e Data final", "warning");
      return true;
    }    

    let routeLink = this.$router.resolve({name: 'relEFD',
      query:{
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : '',
        ano: this.filter.ano,
        dataPagamentoInicial: this.filter.dataPagamentoInicial,
        dataPagamentoFinal: this.filter.dataPagamentoFinal
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
